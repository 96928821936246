/** 首页路由 */
export default[
    {
        path:'/',
        meta: {title: '首页'},
        name:'home',
        component: ()=>import('@/pages/home/home.vue')
    
    }
]
