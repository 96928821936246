<template>
    <Layout  :style="'min-height:'+ innerHeight + 'px'">
        <Header>
            <div class="title_nav_box">
                <img style="margin-left: -20px;" src="../../assets/logo.png" alt="">
                <div class="right_box">
                  <div class="layout-nav">
                    <MenuItem name="logout" style="display: flex;align-items: center;" >
                      <div class="bg-circle">{{name}}</div><span style="color:#fff;">{{userName}}</span>
                    </MenuItem>
                    <div class="show-menu">
                        <div @click="logout">退出登录</div>
                    </div>
                  </div>
                </div>
            </div>
        </Header>
        <Layout style="z-index:1">
            <Sider>
              <Menu ref="menu" theme="light" width="auto" :style="'min-height:'+ (innerHeight-64) + 'px'">
                <div>
                    <div v-for="(item,index) in muen" :key="index">
                        <div v-if="item.children.length>0">
                            <Submenu :name="item.unique_auth" >
                                <template slot="title">
                                    <div class="menu_child_cla">                                        
                                        <Icon :type="item.icon" style="margin-right: 10px;"></Icon>                                        
                                        <div>{{ item.title }}</div>
                                    </div>
                                </template>
                                <MenuItem  v-for="(child,idx) in item.children" style="display:flex;align-items: center;" :key="idx" :name="child.unique_auth" :to="child.src" >
                                    <div class="menu_child_cla" style="margin-left: 5px;">
                                        {{ child.title }}
                                    </div>
                                </MenuItem>
                            </Submenu>
                        </div>
                        <div v-else>
                            <MenuItem :name="item.unique_auth" :to="item.src" style="display:flex;align-items: center;">
                                <div class="menu_cla">
                                    <Icon :type="item.icon" style="margin-right: 10px;"></Icon>
                                    <div >{{ item.title }}</div>
                                </div>
                            </MenuItem>
                        </div>
                    </div>
                </div>
              </Menu>
            </Sider>
            <Layout :style="'height:'+ (innerHeight-64) + 'px'">
                <Content>
                    <router-view></router-view>
                </Content>
            </Layout>
        </Layout>
    </Layout>
</template>

<script>
import { loginLogout } from "@/api/index";
import {removeCookies} from '@/utils/helper'
export default {
    data(){
        return{
            innerHeight:0,
            userName:'',
            name:''
        }
    },
    computed: {
        muen(){
            return this.$store.state.menus
        },
    },
    created(){
      this.innerHeight = window.innerHeight - 2;    
        // 定义一个变量保存上一次记录的 innerHeight 值
        let lastInnerHeight = window.innerHeight;
        // 监听 resize 事件
        window.addEventListener('resize', () => {
            // 获取当前 innerHeight 值
            const currentInnerHeight = window.innerHeight;        
            // 如果 innerHeight 值发生改变，则执行相应的操作
            if (currentInnerHeight !== lastInnerHeight) {
                // 执行你想要的方法
                this.innerHeight = window.innerHeight - 2;                   
                // 更新 lastInnerHeight 变量的值
                lastInnerHeight = currentInnerHeight;
            }
        }); 
    },
    mounted(){
        this.innerHeight = window.innerHeight - 2;
        this.userName = JSON.parse(localStorage.getItem("userName"));
        this.name = JSON.parse(localStorage.getItem("userName")).slice(0,1);
    },
    methods:{
        logout() { 
            loginLogout().then(res=>{
                this.$router.push({path: '/'})                
                localStorage.clear()
                removeCookies('authori-zation-huoyun')
                this.$Message.success(res.msg)
            }).catch(err=>{
                this.$Message.error(err.msg)
            })
      },
    }
}
</script>

<style scoped>
.title_nav_box{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.right_box{
  display: flex;
  align-items: center;
}

.layout-nav {
  margin: 0 auto;
  margin-right: 20px;
  position: relative;
}
.layout-nav:hover .show-menu {
  display: inline-block;
}
.bg-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #FFD878;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.show-menu {
  display: none;
  position: absolute;
  width: 100px;
  height: 60px;
  top: 60px;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 #ebeef5;
  text-align: center;
  z-index: 9999;
}
.show-menu div {
  cursor: pointer;
}
.show-menu div:hover{
  background: #d7dde4;
}
.ivu-layout-header{
    background-color: #202741;
}
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu){
    color: #fff;
    background: #3995FE;
}
.ivu-menu-light{
    background-color: #323A52;
    color: #B5C4D0;
} 
.ivu-menu-item{
    color: #B5C4D0;
}
/* 子菜单激活时发生偏移，解决方法：套div加样式 */
.menu_cla {
    display: flex;
    justify-content: flex-start;
    align-items: center;    
}
.menu_child_cla {    
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
</style>