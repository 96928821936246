/** 表单设置路由 */
export default {
    name: 'systemsetup',
    path: 'systemsetup',
    meta: {
        title: '系统设置'
    },
    component: ()=>import('@/pages/systemsetup/systemsetup.vue'),
    children: [
        {
            name: 'submenu-homeset',
            path: '/systemsetup/homeset', //跳转的path
            meta: {
                title: '首页设置'
            },
            component: ()=>import('@/pages/systemsetup/submenu/homeset.vue') //.vue文件
        },
        {
            name: 'submenu-pagecontent',
            path: '/systemsetup/pagecontent',
            meta: {
                title: '页面内容'
            },
            children:[              
                {
                    name: 'submenu-addpage',
                    path: '/systemsetup/pagecontent/addpage', 
                    meta: {
                        title: '添加页面'
                    },
                    component: ()=>import('@/pages/systemsetup/submenu/pagecontentcomponents/addpage.vue') 
                },
                {
                    name: 'submenu-addpage',
                    path: '/systemsetup/pagecontent/editpage',
                    meta: {
                        title: '修改'
                    },
                    component: ()=>import('@/pages/systemsetup/submenu/pagecontentcomponents/addpage.vue') 
                },
            ],
            component: ()=>import('@/pages/systemsetup/submenu/pagecontent.vue'),
        },
        
        {
            name: 'submenu-systemconfig',
            path: '/systemsetup/systemconfig',
            meta: {
                title: '系统配置'
            },
            component: ()=>import('@/pages/systemsetup/submenu/systemconfig.vue')
        },
        // {
        //     name: 'submenu-aboutus',
        //     path: '/systemsetup/aboutus',            
        //     meta: {
        //         title: '关于我们'
        //     },
        //     component: ()=>import('@/pages/systemsetup/submenu/aboutus.vue')
        // },
        {
            name: 'submenu-aboutus',
            path: '/systemsetup/changepassword',            
            meta: {
                title: '修改密码'
            },
            component: ()=>import('@/pages/systemsetup/submenu/changepassword.vue')
        },
    ]
}