import Vue from 'vue'
import VueRouter from 'vue-router'
import mainLayout from "@/components/mainLayout"
import home from "./modules/home"
import placeorder from "./modules/placeorder"
import consultrate from "./modules/consultrate"
import carsetup from "./modules/carsetup"
import systemsetup from "./modules/systemsetup"

Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'hash',
    routes: [
        {
        name: '登录',
        path: '/',
            component: () => import("@/pages/login/login.vue")
        },
        {
            path: '/admin',
            meta: { title: '货运管理系统' },
            component: mainLayout,
            children: [
                ...home,
                placeorder,
                consultrate,
                carsetup,
                systemsetup
            ]
        },
    ]
})

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

router.beforeEach((to, from, next) => {
  window.document.title = "货运管理系统";
  next();
})
export default router
