import request from '@/utils/request'
//登录
export function login (data) {
    return request({
        url: '/login/login',
        method: 'post',
        data
    })
}
// http://192.168.1.199:91/login/logout
//退出
export function loginLogout (data) {
    return request({
        url: '/login/logout',
        method: 'post',
        data
    })
}
// http://192.168.1.199:91/login/modify
// 修改密码
export function loginModify (data) {
    return request({
        url: '/login/modify',
        method: 'post',
        data
    })
}
// http://192.168.1.199:91/car/create
//创建修改车辆
export function createCar (data) {
    return request({
        url: '/car/create',
        method: 'post',
        data
    })
}
// http://192.168.1.199:91/car/del
//删除车辆
export function delCar (data) {
    return request({        
        url: '/car/del',
        method: 'post',
        data
    })
}
// http://192.168.1.199:91/car/list?limit=30&page=1
//车辆列表
export function carList (data) {
    return request({
        url: '/car/list',
        method: 'get',
        params: data
    })
}
// http://192.168.1.199:91/order/list?limit=30&page=1
//显示在线订单
export function orderList (data) {
    return request({
        url: '/order/list',
        method: 'get',
        params: data
    })
}
// 页面内容页面列表
// http://192.168.1.199:91/page/list?limit=30&page=1
export function pageList (data) {
    return request({
        url: '/page/list',
        method: 'get',
        params: data
    })
}
// 页面内容删除页面
// http://192.168.1.199:91/page/del
export function pageDel (data) {
    return request({        
        url: '/page/del',
        method: 'post',
        data
    })
}
// 页面内容新增页面
// http://192.168.1.199:91/page/create
export function pageCreate (data) {
    return request({        
        url: '/page/create',
        method: 'post',
        data
    })
}
// 页面内容页面详情
// http://192.168.1.199:91/page/infos
export function pageInfos (data) {
    return request({        
        url: '/page/infos',
        method: 'post',
        data
    })
}
// 显示系统配置数据
// http://192.168.1.199:91/other/system
export function otherSystem (data) {
    return request({        
        url: '/other/system',
        method: 'post',
        data
    })
}
// 保存系统配置或关于我们
// http://192.168.1.199:91/other/add_system
export function addSystem (data) {
    return request({        
        url: '/other/add_system',
        method: 'post',
        data
    })
}
// 显示diy
// http://192.168.1.199:91/other/diy
export function otherDiy (data) {
    return request({        
        url: '/other/diy',
        method: 'post',
        data
    })
}
// 保存diy
// http://192.168.1.199:91/other/add_diy
export function addDiy (data) {
    return request({    
        url: '/other/add_diy',
        method: 'post',
        data
    })
}
// 咨询订单列表
// http://192.168.1.199:91/order/advice_list?limit=30&page=1&keywords=&start_date=&end_date=
export function adviceList (data) {
    return request({
        url: '/order/advice_list',
        method: 'get',
        params: data
    })
}
// 导出咨询订单
// http://192.168.1.199:91/order/advice_export?keywords=&start_date=&end_date=
export function adviceExport (data) {
    return request({
        url: '/order/advice_export',
        method: 'get',
        params: data
    })
}
// 改价
// http://192.168.1.199:91/order/modifyMoney
export function modifyMoney (data) {
    return request({    
        url: '/order/modifyMoney',
        method: 'post',
        data
    })
}
// 改价列表
// http://192.168.1.199:91/order/modify_money_list?id=1&limit=30&page=1
export function moneyList (data) {
    return request({
        url: '/order/modify_money_list',
        method: 'get',
        params: data
    })
}
//修改送达时间
// http://192.168.1.199:91/order/modify_time
export function modifyTime (data) {
    return request({    
        url: '/order/modify_time',
        method: 'post',
        data
    })
}
// 完成订单
// http://192.168.1.199:91/order/modify_status
export function modifyStatus (data) {
    return request({    
        url: '/order/modify_status',
        method: 'post',
        data
    })
}
// 物流信息列表
// http://192.168.1.199:91/order/list_log?id=1&limit=30&page=1
export function listLog (data) {
    return request({
        url: '/order/list_log',
        method: 'get',
        params: data
    })
}
// 添加物流信息
// http://192.168.1.199:91/order/add_log
export function addLog (data) {
    return request({    
        url: '/order/add_log',
        method: 'post',
        data
    })
}
// 删除物流信息
// http://192.168.1.199:91/order/del_log
export function delLog (data) {
    return request({    
        url: '/order/del_log',
        method: 'post',
        data
    })
}
// http://192.168.1.199:91/page/select
// 页面列表下拉
export function pageSelect (data) {
    return request({
        url: '/page/select',
        method: 'get',
        params: data
    })
}